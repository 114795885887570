'use strict';

var $ = require('jquery');
var _ = require('lodash');


function hasClass( ele, class1 ) {
	var classes = ele.className;
	var regex = new RegExp('\\b' + ' ' + class1 + '\\b');
	var hasOne = classes.match(regex);

	return hasOne;
}

function toggleClass(ele, class1) {
	var classes = ele.className;
	var regex = new RegExp('\\b' + ' ' + class1 + '\\b');
	var hasOne = classes.match(regex);
	class1 = class1.replace(/\s+/g, '');

	if (hasOne) {
		ele.className = classes.replace(regex, '');
	} else {
		ele.className = classes +' '+ class1;
	}
	return !hasOne;
}

function addClass(ele, class1) {
	var classes = ele.className;
	var regex = new RegExp('\\b' + ' ' + class1 + '\\b');
	var hasOne = classes.match(regex);
	class1 = class1.replace(/\s+/g, '');

	if (!hasOne) {
		ele.className = classes +' '+ class1;
	}
}

function removeClass(ele, class1) {
	var classes = ele.className;
	var regex = new RegExp('\\b' + ' ' + class1 + '\\b');
	var hasOne = classes.match(regex);
	class1 = class1.replace(/\s+/g, '');

	if (hasOne) {
		ele.className = classes.replace(regex, '');
	}
}

module.exports = {
	toggleClass : toggleClass,
	hasClass : hasClass,
	addClass : addClass,
	removeClass : removeClass
};
