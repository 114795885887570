'use strict';
var $ = require('jquery');




function init(){

    $('a:not(.filters a, .cookie-consent-trigger, [href*=cookie-consent])').each(function() {
        if ( location.hostname === this.hostname || !this.hostname.length ) {

            var link = $(this).attr("href");

            $(this).click(function(e) {
                e.preventDefault();
                $('html').addClass('fadeSiteOut');
                setTimeout(function() {
                    window.location = link;
                }, 500);

            });

        }
    });

    /*
    Preloader Versions
    $(window).load(function() {
        $('.preloader').fadeOut('slow');
    });
    */


}

module.exports = {
    init: init
};