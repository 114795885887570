var extend = require('jquery-extend');

var profile = process.env.CONF;
var conf = require('./default');

if (profile) {
	conf = extend(true, {}, conf, require('./' + profile));
}

// expose env var so that envify can pick it up
// process.env.MEINE_REISE_API = __dirname + '/../api/' + conf.api

module.exports = conf;
