'use strict';
var $ = require('jquery');

function addToDOM(fileName) {
	$.get(fileName, function (svgData) {
		var $div = $('<div></div>').css({ display: 'none' });
		$div.html(new XMLSerializer().serializeToString(svgData.documentElement));
		$('body').prepend($div);
	});
}

module.exports = {
	addToDOM: addToDOM
};
