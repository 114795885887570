'use strict';
var $ = require('jquery');
var utils = require('../js-utils/js-utils');
var svgsprite = require('../svg/svg');

function init(){
	var header = document.querySelector('.hansano-header--js'),
		obfuscator = document.querySelector('.hansano-drawer__obfuscator--js');

	if ( !header ) { return; }

	var controls = header.querySelector('.hansano-header-controls--js'),
		btnDrawer = controls.querySelector('.hansano-header-controls__drawer'),
		btnDrawerLabel = btnDrawer.querySelector('.hansano-header-controls__drawer-label'),
		drawer = document.querySelector('.hansano-drawer--js');

		//toggle mobile-menu
		var toggleDrawer = function() {

			var drawerIsHidden = utils.toggleClass(header, 'hansano-drawer--is-visible' );

			if (drawerIsHidden) {
				utils.addClass(obfuscator, 'hansano-drawer__obfuscator--is-visible');
			} else {
				utils.removeClass(obfuscator, 'hansano-drawer__obfuscator--is-visible');
			}

			// toggle Text of toggle-button
			var currText = btnDrawerLabel.innerText,
				targetText = btnDrawerLabel.getAttribute('data-toggle');

				btnDrawerLabel.innerText = targetText;
				btnDrawerLabel.setAttribute('data-toggle', currText);

			if (drawerIsHidden) {
				$(drawer).slideDown( 300 );
			} else {
				$(drawer).slideUp( 300 );
			}
		},

		// toggle Mobile-Menu and Searchbar
		toggleElements = function (){
			var drawerIsVisible = utils.hasClass(header, 'hansano-drawer--is-visible' );

			if (drawerIsVisible) { toggleDrawer(); }
		};

		//toggle Drawer-Menu
		$(btnDrawer).on('click', toggleDrawer );

		$(obfuscator).on( 'click', toggleElements );

        svgsprite.addToDOM(window.APP.svgSpriteUrl || '/img/sprite/svg.svg');

}


module.exports = {
	init: init
};
