'use strict';

var $ = require('jquery');
var slick = require('slick-carousel');


function init() {
    /*console.log('stage init');*/
	$('.stage.stage--js').each(function(index, component) {
		var $component = $(component),
			$stage = $component.find('.stage-stage'),
			$arrowPrev = $component.find('.slider-button--previous'),
			$currentSlide = $component.find('.slider-menu--current'),
			$slideCount = $component.find('.slider-menu--count'),
			$arrowNext = $component.find('.slider-button--next');

		// -----------------------------
		// ---- Stage
		// ----
		// - one fullwidth Image
		// - infinite scrolling
		// - dots on mobile
		// - arrow-buttons
		// ----
		$stage.on('init', function(event,slick){
			$currentSlide.html(slick.currentSlide+1);
			$slideCount.html(slick.slideCount);
		});

		$stage.on('afterChange', function(event,slick) {
			$currentSlide.html(slick.currentSlide+1);
		});

		$stage.slick({
			autoplay: false,
			autoplaySpeed: 5000,
			mobileFirst: true,
			infinite: true,
			dots: true,
			focusOnSelect: true,
			nextArrow: $arrowNext,
			prevArrow: $arrowPrev,
			draggable: false,
			arrows: true,
            fade: true,
            cssEase: 'ease-out',
            useTransform: true,
            lazyLoad: 'progressive',
			responsive: [
			    {
			      	breakpoint: 644,
		      		settings: {
			        	arrows:true
			      	}
			    }
			]
		});
	});
}

module.exports = {
	init:init
};


