
'use strict';
var $ = require('jquery');

var scrollstart = parseInt($('#toTop').data('scrollstart'));

function scrollFunction() {
    if (document.body.scrollTop > scrollstart || document.documentElement.scrollTop > scrollstart) {
        $('#toTop').fadeIn();
    } else {
        $('#toTop').fadeOut();
    }
}


function init(){

    window.onscroll = function() {
        scrollFunction();
    };


    $('#toTop').on('click',function(e) {
        var target = 'header';
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
            //window.location.hash = target;
        });
    });
}

module.exports = {
    init: init
};