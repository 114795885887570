"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * Utilities
 * @object
 * @constructor
 * @public
 */
var Utils = {
  /**
   * Cookie API
   */
  cookie: {
    /**
     * Get cookie by name
     * @param {string} name The cookie name
     * @returns {string} The cookie value
     */
    get: function get(name) {
      var cookieName = encodeURIComponent(name) + '=';
      var cookieStart = document.cookie.indexOf(cookieName);
      var cookieValue = null;
      var cookieEnd;

      if (cookieStart > -1) {
        cookieEnd = document.cookie.indexOf(';', cookieStart);

        if (cookieEnd === -1) {
          cookieEnd = document.cookie.length;
        }

        cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
      }

      return cookieValue;
    },

    /**
     * Set cookie by name
     * @param {string} name The cookie name
     * @param {string} value The cookie value
     * @param {number} expires The cookie expiration date in days
     * @param {string} path The cookie path
     * @param {string} domain The cookie domain
     * @param {boolean} secure The cookie secure flag
     */
    set: function set(name, value, expires, path, domain, secure) {
      var cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      if (expires) {
        var date = new Date();
        date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
        cookieText += '; expires=' + date.toUTCString();
      }

      if (path) {
        cookieText += '; path=' + path;
      }

      if (domain) {
        cookieText += '; domain=' + domain;
      }

      if (secure) {
        cookieText += '; secure';
      }

      document.cookie = cookieText;
    },

    /**
     * Unset a defined cookie
     * @param {string} name The cookie name
     * @param {string} path The cookie path
     * @param {string} domain The cookie domain
     * @param {boolean} secure The cookie secure flag
     */
    unset: function unset(name, path, domain, secure) {
      this.set(name, '', new Date(0), path, domain, secure);
    }
  }
};
var _default = Utils;
exports.default = _default;