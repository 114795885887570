'use strict';
var $ = require('jquery');

// Check if the browser supports HTML5 history
var historySupport = !!(window.history && history.pushState);

function onFilterClick (e) {
    // console.log('onFilterClick');
    e.preventDefault();
    $('.filters a').removeClass('active');
    $(this).addClass('active');
    var $filter = $(e.currentTarget);
    var href = $filter.attr('href');
    if (historySupport) {
        // Update the browser's address bar
        history.pushState(null, null, href);
    }
    /*console.warn('bubu');*/

    $.ajax($filter.attr('href'), {
        dataType : 'html',
        success : function (response) {
            refreshProjects(response);
        }
    });
}

function refreshProjects (html) {
    // Update the <title> tag
    document.title = $(html).filter('title').text();
    if (historySupport) {
        // Update the url
        window.history.pushState({'html':html.html,'pageTitle':html.pageTitle},'', html.urlPath);
    }
    // Update the .projects DOM element with new HTML
    var $html = $('<div />').append(html);

    var content = $html.find('.productDetail').html();


    var target = '.filters';
    var $target = $(target);

    $('.productDetail').animate({opacity: 0}, 400, function() {
        $('.productDetail').html(content);

        /**
         * Call picturefill() with an array of all img elements contained in .productDetail.
         * In IE the product images on subProduct pages where not loaded, because
         * picturefill was not setting the src attribute on the img tags on the hidden tab contents.
         * So we call it manually before showing the new content.
         * Ticket: HW-49
         **/
        // console.log('call picturefill for:', $('.productDetail img'));
        picturefill({
            elements: $('.productDetail img')
        });

        $('.productDetail').animate({opacity: 1}, 400);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 30
        }, 900, 'swing', function () {
        });
    });


}

function toggleNutritionTable() {
    $(this).toggleClass('open');
    $('.productDetail-container .table--wrapper').toggleClass('slidedown slideup');
}

function toggleProductImages() {
    // btn
    $('.img-container .btn-variant-toggle').removeClass('btn__active');
    $(this).addClass('btn__active');

    // toggle images
    var newImg = $('#' + $(this).data('toggle'));

    $('.img-container .teaser-image:visible').fadeOut( 'slow', function() {
        newImg.fadeIn('slow');
    });


    // get color from btn-data
    var fatContentClass =  $(this).data('color');
    // remove actual class and re-set
    $('.productDetail-container h2').removeAttr('class').addClass('product-info__headline--' + fatContentClass);

}

function init(){
    $('body').on('click', '.filters a', onFilterClick);
    $('body').on('click', '.product-info #nutrition-toggle', toggleNutritionTable);
    $('body').on('click', '.img-container .btn-variant-toggle', toggleProductImages);
}

module.exports = {
    init: init
};