'use strict';

var $ = require('jquery');

function init() {
	var $videoElement = $('.video-element--js') || false;

	if (!$videoElement) {
		return;
	}

 /* // This code loads the IFrame Player API code asynchronously
  if (!window['YT']) {
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }*/

	// Player
	$videoElement.each(function(index, component) {
		var player;
		var playerId = this.getAttribute('data-player-id') || false;
		var videoId = this.getAttribute('data-video-id') || false;

		if (!playerId && !videoId) {
			return;
		}

		$(this).on('click', function(event) {
			player = new YT.Player('video-element__player-' + playerId, {
				width: '100%',
				height: '100%',
				videoId: videoId,
				host: 'https://www.youtube.com',
				playerVars: {
					'autoplay': 1,
					'controls': 1,
					'autohide': 1,
					origin: window.location.origin
				},
				events: {
					'onReady': function(event) {
						event.target.playVideo();
					},
					'onStateChange': function(event) {
						if (event.data == YT.PlayerState.ENDED) {
							player.destroy();
						}
					}
				}
			});

			event.preventDefault();
		});
	});
}

module.exports = {
	init: init
};
