
'use strict';
var $ = require('jquery');



function scrollFunction() {
    if (document.body.scrollTop > scrollstart || document.documentElement.scrollTop > scrollstart) {
        $('#toTop').fadeIn();
    } else {
        $('#toTop').fadeOut();
    }
}

function scrollReveal() {

    //get viewport size
    var windowHeight = $(window).height(),
        windowWidth = $(window).width(),
        initialScroll = $(window).scrollTop(),
        items = $('.js-reveal'),
        scroll;

    //hide anything not in the viewport
    items.each(function(){
        if($(this).offset().top >= windowHeight){
            $(this).addClass('js-reveal--hidden');
        }
    });

    //on scroll
    $(window).scroll(function (event) {
        //get the current scroll position
        scroll = $(window).scrollTop();
        //show anything that is now in view (scroll + windowHeight)
        items.each( function(){
            if($(this).offset().top <= (scroll + windowHeight)){
                $(this).removeClass('js-reveal--hidden');
            }
        });
    });

    return this;
};


function init(){
    scrollReveal();
}

module.exports = {
    init: init
};