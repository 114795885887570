var production = process.env.NODE_ENV === 'production';

module.exports = {
	'name': 'default',
	'stampAssetUrls': production,
	'minifyJs': production,
	'logging': {
		'exitOnError': false,
		'default': {
			'isDefault': true,
			'console': {
				'handleExceptions': true,
				'level': 'debug',
				'colorize': true,
				'prettyPrint': true,
				'timestamp': true
			}
		},
		'client': {
			'console': {
				'level': 'silly',
				'colorize': true,
				'prettyPrint': true,
				'timestamp': true
			}
		}
	}
};
