'use strict';

var conf = require('./conf');
var $ = require('jquery');
window.jQuery = $;

window.screenXs = 480;
window.screenSm = 768;
window.screenMd = 992;
window.screenLg = 1300;

/* List Module Js Here */

var header = require('elements/header');
var productDetail = require('elements/productDetail');
var stage = require('elements/stage');
var smoothScroll = require('elements/smoothScroll');
var scrollReveal = require('elements/scrollReveal');
var animations =  require('elements/animations');
var video = require('elements/video');
var cookieConsent = require('elements/cookieconsent.js');

//-----------------------------------------

var isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (
			isMobile.Android() ||
			isMobile.BlackBerry() ||
			isMobile.iOS() ||
			isMobile.Opera() ||
			isMobile.Windows()
		);
	}
};



function init() {
	console.log('Initializing with', conf);
	console.log('Having jQuery version', $().jquery);
    header.init();
    productDetail.init();
    stage.init();
    smoothScroll.init();
    scrollReveal.init();
    animations.init();
    video.init();
	cookieConsent.init();
}

var App = {
	init: init
};

$(function() {
	$(document).ready(function() {
	    App.init();
	});
});
